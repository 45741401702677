import React, { FC } from "react";

import { ResizeMode } from "expo-av";
import { BookmarkSimple } from "phosphor-react-native";
import { TouchableOpacity, View } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";

import { StudioAnimation, StudioAnimationStatus, VideoStatus } from "@app/common/graphql/generated/schema.graphql";
import { Video } from "@app/components/common/video/video.component";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";

import { styles } from "./studio-video-prompt.style";

interface Props {
  video: StudioAnimation;
  onPress: (video: StudioAnimation) => void;
  isSelected: boolean;
}

export const StudioVideoPrompt: FC<Props> = ({ onPress, isSelected, video }) => {
  const { colors } = useTheme();
  const { width: windowWidth } = useWindowDimensions();

  const videoWidth = windowWidth - 40;
  const videoLoading = video.videoStatus === VideoStatus.processing;

  const handlePress = (): void => {
    onPress(video);
  };

  return (
    <TouchableOpacity onPress={handlePress} disabled={videoLoading} style={styles.root}>
      <View style={[{ backgroundColor: colors.background }, ...conditionalItem(isSelected, { borderColor: colors.secondary, borderWidth: 2 })]}>
        <View style={[styles.loadingContainer, { height: videoWidth, width: videoWidth }]}>
          <ActivityIndicator size="large" />
        </View>
        <Video
          cloudflareId={video.cloudflareId}
          source={video.videoManifestUrl ? { uri: video.videoManifestUrl } : undefined}
          posterSource={{ uri: video.thumbnailUrl }}
          displayLoadingIndicator
          shouldPlay
          isLooping
          forceReady={!!video.videoManifestUrl}
          resizeMode={ResizeMode.CONTAIN}
          height={videoWidth}
          width={videoWidth}
        />

        {video.studioAnimationStatus === StudioAnimationStatus.permanent && (
          <BookmarkSimple weight="fill" color={colors.common.white} style={styles.imageIcon} />
        )}
      </View>
    </TouchableOpacity>
  );
};
