import React, { FC } from "react";

import { UploadSimple } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { Text } from "@app/components/common/text/text.component";

import { styles } from "./upload-pitch-video-button.style";

// TODO(BLOOM-3298): Use the right type for pitchVideo
interface Props extends Omit<ButtonProps, "children"> {
  pitchVideo?: string;
  setPitchVideo: (video?: string) => void;
  onCompleted: () => void;
}

export const UploadPitchVideoButton: FC<Props> = ({ /*pitchVideo,*/ setPitchVideo, onCompleted, ...buttonProps }) => {
  const { t } = useTranslation();

  const handleUpload = (): void => {
    // TODO(BLOOM-3298): implement upload video
    setPitchVideo("https://customer-g5yxvnr7llhw6l3x.cloudflarestream.com/6fa2524911202effeda11d6b1b0d22e0/manifest/video.m3u8");
    onCompleted();
  };

  return (
    <View style={styles.root}>
      <Button size="large" mode="contained" icon={UploadSimple} onPress={handleUpload} {...buttonProps}>
        {t("startCampaign.uploadVideo.label")}
      </Button>
      <Text variant="caption" color="tertiary" textAlign="center">
        {t("startCampaign.uploadVideo.advantage")}
      </Text>
    </View>
  );
};
