import { LazyQueryExecFunction, QueryHookOptions, gql, useLazyQuery, useQuery } from "@apollo/client";

import { collabWithMediaFields } from "@app/common/graphql/fragments.graphql";
import { CollabWithMedia } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  collab: CollabWithMedia;
}

interface CollabIdVariable {
  collabId: string;
}

interface HandleVariable {
  handle: string;
}

type Variables = CollabIdVariable | HandleVariable;

interface LazyResult extends QueryResult<Response, "collab"> {
  getCollab: LazyQueryExecFunction<Response, Variables>;
}

/**
 * TODO: This query is also used to query collab for the thumbnail preview. Update it accordingly:
 * server/services/collab.service.js
 */
const collabWithMediaQuery = gql`
  query CollabWithMedia($collabId: CollabId, $handle: String) {
    collab: collabWithMedia(collabId: $collabId, handle: $handle) {
      ...CollabWithMediaFields
    }
  }
  ${collabWithMediaFields}
`;

export function useCollabWithMedia(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "collab"> {
  const variables = options.variables;
  const { data, loading, error } = useQuery<Response, Variables>(collabWithMediaQuery, {
    skip: !variables || ("collabId" in variables && !variables.collabId) || ("handle" in variables && !variables.handle),
    ...options,
  });

  return { data: data?.collab, loading, error };
}

export function useLazyCollabWithMedia(): LazyResult {
  const [getCollab, { loading, error, data }] = useLazyQuery<Response, Variables>(collabWithMediaQuery, {
    notifyOnNetworkStatusChange: true,
  });

  return { getCollab, loading, error, data: data?.collab };
}
