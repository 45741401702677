import React, { FC, useState } from "react";

import { BlurView } from "expo-blur";
import { MediaTypeOptions } from "expo-image-picker";
import { Play, X } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { FAB as Fab, useTheme } from "react-native-paper";

import { VideoStatus } from "@app/common/graphql/generated/schema.graphql";
import { AvatarImage } from "@app/components/common/avatar-image/avatar-image.component";
import { Button } from "@app/components/common/button/button.component";
import { AlertVariant, EmbeddedAlert } from "@app/components/common/embedded-alert/embedded-alert.component";
import { Text } from "@app/components/common/text/text.component";
import { useConfigContext } from "@app/context/config/config.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useAuthProfile } from "@app/hooks/api/use-auth-profile.hook";
import { useUpdateProfile } from "@app/hooks/api/use-update-profile.hook";
import { useUploadVideoInitialContainerQuery } from "@app/hooks/api/use-upload-video-initial-container.hook";
import { useUploadVideo } from "@app/hooks/api/use-upload-video.hook";
import { formatSeconds } from "@app/utils/duration.util";
import { pickImage } from "@app/utils/image-picker.util";
import { reportError } from "@app/utils/logger/logger.util";

import { styles } from "./edit-profile-video.style";

export const EditProfileVideo: FC = () => {
  const avatarImageSize = 90;

  const { t } = useTranslation();
  const { colors, roundness } = useTheme();
  const { showErrorSnackbar } = useSnackbarContext();

  const { config } = useConfigContext();
  const { profile } = useProfileContext();
  const { getAuthProfile } = useAuthProfile();
  const { getUploadVideoInitialContainer, loading: uploadVideoInitialContainerLoading } = useUploadVideoInitialContainerQuery();
  const { uploadVideo, loading: uploadVideoLoading } = useUploadVideo();
  const { updateProfile, loading: updateProfileLoading } = useUpdateProfile();

  const [isDeleting, setIsDeleting] = useState(false);

  const videoStatus = profile?.video?.status;
  const uploadingVideo = uploadVideoInitialContainerLoading || uploadVideoLoading || updateProfileLoading;
  const videoProcessing = videoStatus === VideoStatus.processing || videoStatus === VideoStatus.created;

  const thumbnailUrl = profile?.video?.thumbnailUrl;
  const videoUrl = profile?.video?.videoManifestUrl;
  const addVideoDisabled = uploadingVideo || videoProcessing || !!videoUrl;

  const maximumVideoDurationInSeconds = config?.maximumVideoDurationForProfileInSeconds;
  const maximumVideoDurationFormatted = formatSeconds(maximumVideoDurationInSeconds || 0);

  const chooseAndUploadVideo = async (): Promise<void> => {
    try {
      const video = await pickImage({ mediaTypes: MediaTypeOptions.Videos, videoMaxDuration: maximumVideoDurationInSeconds });
      if (video.error || !profile || !video.asset) return;

      const blob = await fetch(video.asset.uri).then(response => response.blob());

      void getUploadVideoInitialContainer({
        variables: { userId: profile.userId },
        onCompleted: data => {
          void uploadVideo({
            variables: { input: blob, url: data.uploadVideoInitialContainer.url },
            onCompleted: () => {
              void updateProfile({
                variables: { userId: profile.userId, input: { uploadVideoId: data.uploadVideoInitialContainer.uploadVideoId } },
                onError: error => showErrorSnackbar({ error }),
              });
            },
            onError: error => showErrorSnackbar({ error }),
          });
        },
        onError: error => showErrorSnackbar({ error }),
      });
    } catch (e) {
      reportError(e as Error);
    }
  };

  const handleDelete = (): void => {
    if (!profile?.userId) return;

    setIsDeleting(true);
    void updateProfile({
      variables: { userId: profile.userId, input: { uploadVideoId: null } },
      onCompleted: () => setIsDeleting(false),
      onError: () => setIsDeleting(false),
    });
  };

  const handleRefetchProfile = (): void => {
    if (!profile?.username) return;

    void getAuthProfile({
      onError: error => showErrorSnackbar({ error }),
    });
  };

  return (
    <View style={styles.root}>
      {!!profile && !videoProcessing && thumbnailUrl && (
        <View style={styles.videoPreviewWithFabContainer}>
          <AvatarImage image={thumbnailUrl} fallbackText="" size={avatarImageSize} style={styles.preview} />
          <Fab
            onPress={handleDelete}
            icon={X}
            style={[{ backgroundColor: colors.tertiaryContainer, borderRadius: roundness }, styles.deleteButton]}
            size="small"
            mode="elevated"
          />
          <BlurView style={styles.playContainer}>
            <Play color={colors.common.white} weight="fill" size={24} />
          </BlurView>
        </View>
      )}

      <View style={styles.buttonWithDescription}>
        <Button
          mode="outlined"
          disabled={addVideoDisabled}
          loading={uploadingVideo || videoProcessing}
          fullWidth
          onPress={() => void chooseAndUploadVideo()}>
          {t("userInfo.fields.uploadVideoId.addVideo")}
        </Button>
        {!addVideoDisabled && (
          <Text variant="caption" color="tertiary" style={styles.uploadDescription}>
            {t("userInfo.fields.uploadVideoId.uploadDescription", { maximumVideoDuration: maximumVideoDurationFormatted })}
          </Text>
        )}
      </View>

      {videoProcessing && !isDeleting && (
        <EmbeddedAlert
          variant={AlertVariant.primary}
          text={t("userInfo.fields.uploadVideoId.currentlyBeingProcessed")}
          cta={{ label: t("cta.refresh"), onPress: handleRefetchProfile }}
        />
      )}
    </View>
  );
};
