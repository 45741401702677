import React, { FC } from "react";

import { View } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";

import { Video } from "@app/components/common/video/video.component";

import { styles } from "./pitch-video-preview.style";

// TODO(BLOOM-3298): Use the right type for pitchVideo
interface Props {
  pitchVideo?: string;
}

export const PitchVideoPreview: FC<Props> = ({ pitchVideo }) => {
  const { colors } = useTheme();

  // TODO(BLOOM-3298): use pitchVideo?.status === VideoStatus.processing
  const loading = false;

  if (!pitchVideo) return null;

  if (loading) {
    return (
      <View style={[styles.video, styles.loadingContainer, { borderColor: colors.surfaceDisabled }]}>
        <ActivityIndicator size="large" color={colors.tertiary} />
      </View>
    );
  }

  // TODO(BLOOM-3298): pass the right value to source, posterSource and cloudflareId
  return (
    <Video
      source={{ uri: pitchVideo }}
      posterSource={{
        uri: "https://customer-g5yxvnr7llhw6l3x.cloudflarestream.com/6fa2524911202effeda11d6b1b0d22e0/thumbnails/thumbnail.jpg?height=1500",
      }}
      cloudflareId="6fa2524911202effeda11d6b1b0d22e0"
      useNativeControls
      displayLoadingIndicator
      videoStyle={styles.video}
      posterStyle={styles.video}
      style={[styles.video, { backgroundColor: colors.background }]}
    />
  );
};
