import React, { useEffect, useMemo, Dispatch, SetStateAction, FC, useState, ReactNode } from "react";

import { ResizeMode } from "expo-av";
import { Image, ImageProps as ExpoImageProps } from "expo-image";
import { GestureResponderEvent, StyleProp, View, ViewStyle } from "react-native";
import { ActivityIndicator, TouchableRipple, useTheme } from "react-native-paper";
import Animated, { AnimatedStyle, Easing, useAnimatedStyle, useSharedValue, withSequence, withTiming } from "react-native-reanimated";

import { imageRatio } from "@app/common/constants/image.const";
import { CollabMediaContent } from "@app/common/graphql/generated/schema.graphql";
import { Skeleton } from "@app/components/common/skeleton/skeleton.component";
import { Video } from "@app/components/common/video/video.component";
import { useFeedContext } from "@app/context/feed/feed.context";
import { previewWidth, useImage } from "@app/hooks/utils/use-image.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { isAndroidApp } from "@app/utils/device.util";
import { getProductMediaId, isAnimation, isImage, isVideo } from "@app/utils/product-media.util";

import { ProductImageTab } from "./product-image-tab/product-image-tab.component";
import { styles } from "./product-images.style";

interface ImageProps extends ExpoImageProps {
  width?: number;
  height?: number;
}

interface Props {
  collabId: string;
  media: CollabMediaContent[];
  loading?: boolean;
  imageProps?: ImageProps;
  background?: ReactNode;
  contentOnImage?: ReactNode;
  contentUnderImage?: ReactNode;
  style?: StyleProp<AnimatedStyle<StyleProp<ViewStyle>>>;
  setCurrentMedia: Dispatch<SetStateAction<CollabMediaContent | undefined>>;
}

const angle = 8;
const time = 150;
const easing = Easing.linear;

export const ProductImages: FC<Props> = ({
  collabId,
  media,
  loading,
  imageProps,
  background,
  style,
  setCurrentMedia,
  contentOnImage,
  contentUnderImage,
}) => {
  const { colors } = useTheme();

  const {
    feed: { currentIndexImageMap, setCurrentIndexImageMap },
  } = useFeedContext();
  const [currentIndexMedia, setCurrentIndexMedia] = useState(0);
  const currentIndex = setCurrentIndexImageMap ? currentIndexImageMap.get(collabId) ?? 0 : currentIndexMedia;
  const currentMedia = media[currentIndex];
  const setCurrentIndex = (indexMedia: number): void => {
    setCurrentMedia(media[indexMedia]);
    setCurrentIndexImageMap ? setCurrentIndexImageMap(new Map(currentIndexImageMap.set(collabId, indexMedia))) : setCurrentIndexMedia(indexMedia);
  };

  const { width, height, style: imageStyle, ...expoImageProps } = imageProps ?? {};
  const imageRotation = useSharedValue(0);
  const { width: windowWidth } = useWindowDimensions();
  const imageWidth = width ?? windowWidth;
  const imageHeight = height ?? imageWidth * imageRatio;
  const imageSizeStyle = useMemo(() => ({ height: imageHeight, width: imageWidth }), [imageHeight, imageWidth]);
  const multipleMedia = media.length > 1;
  const { getResponsiveImageUrl, getPreviewImageUrl, defaultImage } = useImage({ width: imageWidth });

  const animateImage = (value: number): void => {
    imageRotation.value = withSequence(withTiming(value, { duration: time / 2, easing }), withTiming(0, { duration: time / 2, easing }));
  };

  const handleImagePress = (event: GestureResponderEvent): void => {
    const middle = windowWidth / 2;
    if (event.nativeEvent.pageX > middle) {
      if (!multipleMedia || currentIndex === media.length - 1) {
        animateImage(angle);
      }
      if (multipleMedia) {
        setCurrentIndex?.(Math.min(currentIndex + 1, media.length - 1));
      }
    } else {
      if (!multipleMedia || currentIndex === 0) {
        animateImage(-angle);
      }
      if (multipleMedia) {
        setCurrentIndex?.(Math.max(currentIndex - 1, 0));
      }
    }
  };

  useEffect(() => {
    const imagesToPrefetch = media.map(m => (isImage(m) ? getResponsiveImageUrl(m.imageUrl) ?? m.imageUrl : "")).filter(Boolean);
    const previewImagesToPrefetch = media.map(m => (isImage(m) ? getPreviewImageUrl(m.imageUrl) ?? m.imageUrl : "")).filter(Boolean);
    void Image.prefetch([...imagesToPrefetch, ...previewImagesToPrefetch]);
  }, [getPreviewImageUrl, getResponsiveImageUrl, media]);

  const imageTransformStyle = useAnimatedStyle(() => {
    return {
      transform: [{ perspective: 1000 }, { rotateY: `${imageRotation.value}deg` }],
    };
  });

  const previewSource = useMemo(() => {
    if (isImage(currentMedia) && currentMedia.imageUrl) {
      return { uri: getPreviewImageUrl(currentMedia.imageUrl), width: previewWidth, height: previewWidth };
    } else if ((isVideo(currentMedia) || isAnimation(currentMedia)) && currentMedia.videoManifestUrl) {
      return { uri: getPreviewImageUrl(currentMedia.thumbnailUrl), width: previewWidth, height: previewWidth };
    } else {
      return undefined;
    }
  }, [currentMedia, getPreviewImageUrl]);

  const imageComponent = useMemo(() => {
    if (isImage(currentMedia)) {
      const imageSource = currentMedia?.imageUrl ? { uri: getResponsiveImageUrl(currentMedia?.imageUrl), ...imageSizeStyle } : undefined;

      return (
        <Image
          source={imageSource ?? defaultImage}
          placeholder={previewSource}
          responsivePolicy="initial"
          cachePolicy="memory-disk"
          placeholderContentFit="cover"
          contentFit="contain"
          allowDownscaling={!isAndroidApp}
          style={[imageSizeStyle, { backgroundColor: colors.skeleton }, imageStyle]}
          {...expoImageProps}
        />
      );
    } else if ((isVideo(currentMedia) || isAnimation(currentMedia)) && currentMedia.videoManifestUrl) {
      return (
        <Video
          cloudflareId={currentMedia.cloudflareId}
          source={{ uri: currentMedia.videoManifestUrl }}
          posterSource={{ uri: currentMedia.thumbnailUrl }}
          displayLoadingIndicator
          shouldPlay
          isLooping
          resizeMode={ResizeMode.CONTAIN}
          height={imageHeight}
          width={imageWidth}
          videoStyle={imageStyle}
          posterStyle={imageStyle}
          style={[{ backgroundColor: colors.background }, imageStyle]}
        />
      );
    } else {
      return <ActivityIndicator size="large" style={styles.loading} />;
    }
  }, [
    colors.background,
    colors.skeleton,
    currentMedia,
    defaultImage,
    expoImageProps,
    getResponsiveImageUrl,
    imageHeight,
    imageSizeStyle,
    imageStyle,
    imageWidth,
    previewSource,
  ]);

  return (
    <Animated.View style={[{ height: imageHeight, backgroundColor: colors.background }, imageTransformStyle, style]}>
      <Image
        source={previewSource ?? defaultImage}
        placeholder={previewSource}
        responsivePolicy="initial"
        cachePolicy="memory-disk"
        placeholderContentFit="cover"
        contentFit="cover"
        style={styles.previewImage}
      />
      {background}

      <Skeleton show={loading} radius="square" height={imageHeight} width={imageWidth} disableExitAnimation>
        {!loading ? imageComponent : null}
      </Skeleton>

      {/* TODO(BLOOM-3316): implement hold to pause video */}
      <TouchableRipple style={[imageSizeStyle, styles.touchableZone]} rippleColor="transparent" onPress={handleImagePress}>
        <View style={[imageSizeStyle, styles.imageContent]}>
          <View style={styles.topImageContent}>
            {multipleMedia && (
              <View style={styles.imageTabsContainer}>
                {media.map((m, index) => (
                  <ProductImageTab
                    key={`tab-product-media-${getProductMediaId(m)}`}
                    isActive={index === currentIndex}
                    hasMultipleImages={multipleMedia}
                  />
                ))}
              </View>
            )}
          </View>

          <View style={imageSizeStyle}>{contentOnImage}</View>
        </View>
      </TouchableRipple>

      {contentUnderImage}
    </Animated.View>
  );
};
