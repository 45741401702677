/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { FC, useCallback, useMemo, useRef, useState } from "react";

import { useFocusEffect } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { View, FlatList, ListRenderItem, StyleProp, ViewStyle } from "react-native";
import { useTheme } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { StudioAnimation, StudioAnimationStatus, StudioImage, StudioImageStatus, VideoStatus } from "@app/common/graphql/generated/schema.graphql";
import { StudioImageWithIndex } from "@app/common/types/studio.type";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { LoadingPrompt } from "@app/components/studio-mini/loading-prompt/loading-prompt.component";
import { StudioImagePrompt } from "@app/components/studio-mini/studio-image-prompt/studio-image-prompt.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { GenerationTaskType, useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";
import { useSaveTemporaryAnimation } from "@app/hooks/api/studio/use-save-temporary-animation.hook";
import { useStartGeneratingPoses } from "@app/hooks/api/studio/use-start-generating-poses.hook";
import { useStartGeneratingVariations } from "@app/hooks/api/studio/use-start-generating-variations.hook";
import { useTemporaryStudioAnimation } from "@app/hooks/api/studio/use-temporary-studio-animation.hook";
import { useDeleteStudioMedia } from "@app/hooks/api/use-delete-studio-images.hook";
import { useSaveTemporaryImages } from "@app/hooks/api/use-save-temp-images.hook";
import { useTemporaryStudioImages } from "@app/hooks/api/use-temporary-studio-images.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { isStudioImage } from "@app/utils/studio-media.util";

import { BottomSheetPromptSet } from "../bottom-sheet-prompt-set/bottom-sheet-prompt-set.component";
import { StudioVideoPrompt } from "../studio-video-prompt/studio-video-prompt.component";

import { StudioMainActions } from "./studio-main-actions/studio-main-actions.component";
import { StudioNavigationSet } from "./studio-navigation-set/studio-navigation-set.component";
import { styles } from "./studio-prompt-set.style";

interface Props {
  style: StyleProp<ViewStyle>;
  indexSet: number;
  taskId: string;
  task: GenerationTaskType;
  taskCount: number;
  onNext: () => void;
  onPrevious: () => void;
  onReset: () => void;
  onGenerate: () => Promise<void>;
}

export const StudioPromptSet: FC<Props> = ({ style, indexSet, task, taskId, taskCount, onNext, onPrevious, onReset, onGenerate }) => {
  const { colors } = useTheme();
  const sheetRef = useRef<BottomSheetRefProps>(null);
  const { bottom: bottomInset } = useSafeAreaInsets();
  const { t } = useTranslation();

  const { profile } = useProfileContext();
  const { showErrorSnackbar } = useSnackbarContext();
  const { inputs, setGeneration, setIsCurrentlyGenerating } = useStudioMiniContext();

  const [selectedImages, setSelectedImages] = useState<StudioImageWithIndex[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<StudioAnimation | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const userId = profile?.userId;
  const is3dGeneration = task === GenerationTaskType.imageToAnimation;

  const { saveTemporaryImages } = useSaveTemporaryImages();
  const { saveTemporaryAnimation } = useSaveTemporaryAnimation();
  const { deleteStudioMedia } = useDeleteStudioMedia();
  const {
    data: dataTempStudioImages,
    stopPolling: stopPollingTempStudioImages,
    error: errorTempStudioImages,
  } = useTemporaryStudioImages({
    variables: { where: { userId: userId ?? "", studioTaskId: taskId ?? "" } },
    skip: !userId || !taskId || is3dGeneration,
    pollInterval: 2000,
    onCompleted: response => {
      if (response.temporaryStudioImages.nodes && response.temporaryStudioImages.nodes.length > 0) {
        stopPollingTempStudioImages?.();
        setIsCurrentlyGenerating(false);
        setLoading(false);
      } else {
        setIsCurrentlyGenerating(true);
      }
    },
    onError: e => {
      setLoading(false);
      stopPollingTempStudioImages?.();
      setIsCurrentlyGenerating(false);
      showErrorSnackbar({ error: e });
    },
  });
  const {
    data: dataTempStudioAnimation,
    stopPolling: stopPollingTempStudioAnimation,
    error: errorTempStudioAnimation,
  } = useTemporaryStudioAnimation({
    variables: { userId: userId ?? "", where: { studioTaskId: taskId ?? "" } },
    skip: !userId || !taskId || !is3dGeneration,
    pollInterval: 2000,
    onCompleted: response => {
      if (response.temporaryStudioAnimation?.videoStatus === VideoStatus.uploaded && !!response.temporaryStudioAnimation?.videoManifestUrl) {
        stopPollingTempStudioAnimation?.();
        setIsCurrentlyGenerating(false);
        setLoading(false);
      } else {
        setIsCurrentlyGenerating(true);
      }
    },
    onError: e => {
      setLoading(false);
      stopPollingTempStudioAnimation?.();
      setIsCurrentlyGenerating(false);
      showErrorSnackbar({ error: e });
    },
  });
  const { startGeneratingVariations } = useStartGeneratingVariations();
  const { startGeneratingPoses } = useStartGeneratingPoses();

  const data = is3dGeneration ? dataTempStudioAnimation : dataTempStudioImages;
  const error = is3dGeneration ? errorTempStudioAnimation : errorTempStudioImages;
  const areSelectedImageSaved = useMemo(() => selectedImages.every(si => si.status === StudioImageStatus.permanent), [selectedImages]);
  const isSelectedVideoSaved = useMemo(
    () => selectedVideo?.studioAnimationStatus === StudioAnimationStatus.permanent,
    [selectedVideo?.studioAnimationStatus],
  );

  const handleSelectVideo = useCallback(
    (video?: StudioAnimation) => {
      if (video && video !== selectedVideo) {
        setSelectedVideo(video);
        sheetRef.current?.open();
      } else {
        setSelectedVideo(undefined);
        sheetRef.current?.close();
      }
    },
    [selectedVideo],
  );

  const handleSelectImage = useCallback(
    (image?: StudioImageWithIndex): void => {
      if (image && (selectedImages.length !== 1 || selectedImages[0].imageId !== image.imageId)) {
        setSelectedImages(imgs => {
          const imageIndex = imgs.findIndex(s => s.imageId === image?.imageId);
          if (imageIndex === -1) {
            return [...imgs, image];
          } else {
            const newArr = [...imgs];
            newArr.splice(imageIndex, 1);
            return newArr;
          }
        });
        sheetRef.current?.open();
      } else {
        sheetRef.current?.close();
      }
    },
    [selectedImages],
  );

  const handleSelectMedia = is3dGeneration ? handleSelectVideo : handleSelectImage;

  const handleSaveImages = async (): Promise<void> => {
    try {
      if (areSelectedImageSaved) {
        const idToUnSave = selectedImages.map(s => s.imageId);

        await deleteStudioMedia({
          variables: { where: { mediaIds: idToUnSave } },
          onCompleted: () => {
            setSelectedImages(prev => prev.filter(i => idToUnSave.includes(i.imageId)));
          },
        });
      } else {
        const imageIds = selectedImages.filter(si => si.status === StudioImageStatus.temporary).map(imgs => imgs.imageId);

        await saveTemporaryImages({
          variables: { input: { imageIds } },
          onCompleted: () => {
            setSelectedImages(prev => prev.map(i => ({ ...i, status: StudioImageStatus.permanent })));
          },
        });
      }
    } catch (e: unknown) {
      showErrorSnackbar({ error: e as Error });
    }
  };

  const handleSaveVideo = async (): Promise<void> => {
    if (!selectedVideo) return;

    try {
      if (isSelectedVideoSaved) {
        const idToUnSave = selectedVideo.studioAnimationId;

        await deleteStudioMedia({
          variables: { where: { mediaIds: [idToUnSave] } },
          onCompleted: () => {
            setSelectedVideo(undefined);
          },
        });
      } else {
        await saveTemporaryAnimation({
          variables: { input: { studioAnimationId: selectedVideo.studioAnimationId } },
          onCompleted: () => {
            setSelectedVideo(prev => (prev ? { ...prev, studioAnimationStatus: StudioAnimationStatus.permanent } : undefined));
          },
        });
      }
    } catch (e: unknown) {
      showErrorSnackbar({ error: e as Error });
    }
  };

  const handleOnClickGenerateVariations = async (): Promise<void> => {
    if (!userId || !isStudioImage(selectedImages[0])) return;

    setIsCurrentlyGenerating(true);

    sheetRef.current?.close();

    const studioImageId = selectedImages[0].imageId ?? "";
    await startGeneratingVariations({
      variables: {
        input: {
          studioImageId,
          contestId: inputs.contest?.id,
        },
        userId,
      },
      onCompleted: response =>
        setGeneration(prev => ({
          ...prev,
          taskIds: { ...(prev.taskIds ?? {}), [response.startGeneratingVariations.studioTaskId]: GenerationTaskType.variation },
        })),
      onError: e => {
        showErrorSnackbar({ error: e, message: t("error.generic") });
        sheetRef.current?.open();
        setIsCurrentlyGenerating(false);
      },
    });
  };

  const handleOnClickGeneratePose = async (): Promise<void> => {
    if (!userId || !isStudioImage(selectedImages[0])) return;

    setIsCurrentlyGenerating(true);

    sheetRef.current?.close();

    const studioImageId = selectedImages[0].imageId ?? "";
    await startGeneratingPoses({
      variables: {
        input: {
          studioImageId,
          contestId: inputs.contest?.id,
        },
        userId,
      },
      onCompleted: response =>
        setGeneration(prev => ({
          ...prev,
          taskIds: { ...(prev.taskIds ?? {}), [response.startGeneratingPoses.studioTaskId]: GenerationTaskType.pose },
        })),
      onError: e => {
        showErrorSnackbar({ error: e, message: t("error.generic") });
        sheetRef.current?.open();
        setIsCurrentlyGenerating(false);
      },
    });
  };

  const handleOnPrevious = (): void => {
    handleSelectMedia();
    onPrevious();
  };

  const handleOnNext = (): void => {
    handleSelectMedia();
    onNext();
  };

  const renderItem = useCallback<ListRenderItem<StudioImage>>(
    ({ item, index: indexImage }) => (
      <StudioImagePrompt
        item={item}
        indexImage={indexImage}
        onPress={handleSelectImage}
        isSelected={!!selectedImages.find(img => isStudioImage(img) && img.imageId === item.imageId)}
        itemSelectedCount={selectedImages.length}
      />
    ),
    [handleSelectImage, selectedImages],
  );

  useFocusEffect(
    useCallback(() => {
      return () => sheetRef.current?.close();
    }, []),
  );

  return (
    <View style={style}>
      <StudioNavigationSet
        style={[styles.pagePadding, styles.navigationContainer]}
        index={indexSet}
        onPrevious={handleOnPrevious}
        onNext={handleOnNext}
        taskCount={taskCount}
        taskType={task}
      />
      {is3dGeneration && data?.__typename === "StudioAnimation" && data.videoStatus === VideoStatus.uploaded ? (
        <StudioVideoPrompt video={data} isSelected={!!selectedVideo} onPress={handleSelectVideo} />
      ) : (
        <FlatList
          showsVerticalScrollIndicator={false}
          contentContainerStyle={[styles.listContainer, styles.pagePadding, ...conditionalItem(!!loading || !!error, { flex: 1 })]}
          ListEmptyComponent={<LoadingPrompt loading={loading} error={error} taskId={taskId} taskType={task} />}
          keyExtractor={node => node.imageId}
          columnWrapperStyle={styles.listColumnContainer}
          data={data && "nodes" in data ? data?.nodes : undefined}
          numColumns={2}
          renderItem={renderItem}
          extraData={[selectedImages]}
        />
      )}
      {!loading && !error ? (
        <StudioMainActions
          style={{ backgroundColor: colors.tertiaryContainer, marginBottom: bottomInset }}
          onReset={onReset}
          onPressGenerate={onGenerate}
        />
      ) : null}
      <BottomSheetPromptSet
        ref={sheetRef}
        generationType={task}
        onDismiss={() => {
          setSelectedImages([]);
          setSelectedVideo(undefined);
        }}
        onClose={() => handleSelectMedia()}
        handleStyle={styles.handleContainer}
        contentContainerStyle={styles.bottomSheetContainer}
        selectedImages={selectedImages}
        selectedVideo={selectedVideo}
        onGenerateVariations={task === GenerationTaskType.original ? handleOnClickGenerateVariations : undefined}
        onGeneratePoses={task === GenerationTaskType.pose ? undefined : handleOnClickGeneratePose}
        onSave={is3dGeneration ? handleSaveVideo : handleSaveImages}
      />
    </View>
  );
};
