import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { ClientName } from "@app/common/enums/client-name.enum";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  uploadVideo: {
    url: string;
  };
}

interface Variables {
  input: Blob;
  url: string;
  pathBuilder?: (variables: { args: Variables }) => string;
}

interface UploadStudioVideoResult extends MutationResult<Response, "uploadVideo"> {
  uploadVideo: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

function pathBuilder(variableSources: { args: Variables }): string {
  return variableSources.args.url;
}

const uploadVideoMutation = gql`
  # eslint-disable-next-line @graphql-eslint/known-type-names
  mutation UploadVideo($input: FormData!, $pathBuilder: any, $url: Url!) {
    # eslint-disable-next-line @graphql-eslint/fields-on-correct-type
    uploadVideo(input: $input, url: $url)
      @rest(bodyKey: "input", bodySerializer: "fileEncode", endpoint: "dynamic", method: "PUT", pathBuilder: $pathBuilder, type: "File") {
      url
    }
  }
`;

export function useUploadVideo(): UploadStudioVideoResult {
  const [uploadVideo, { loading, error, data }] = useMutation<Response, Variables>(uploadVideoMutation, {
    context: { clientName: ClientName.externalFileUploadRest },
    variables: { pathBuilder, input: new Blob(), url: "" },
  });

  return { uploadVideo, loading, error, data: data?.uploadVideo };
}
