import React, { FC } from "react";

import { useTheme } from "react-native-paper";

import { Video } from "@app/components/common/video/video.component";

import { styles } from "./tips-pitch-video.style";

export const TipsPitchVideo: FC = () => {
  const { colors } = useTheme();

  // TODO(BLOOM-3174): pass the right value to source, posterSource and cloudflareId
  return (
    <Video
      source={{ uri: "https://customer-g5yxvnr7llhw6l3x.cloudflarestream.com/6fa2524911202effeda11d6b1b0d22e0/manifest/video.m3u8" }}
      posterSource={{
        uri: "https://customer-g5yxvnr7llhw6l3x.cloudflarestream.com/6fa2524911202effeda11d6b1b0d22e0/thumbnails/thumbnail.jpg?height=1500",
      }}
      cloudflareId="6fa2524911202effeda11d6b1b0d22e0"
      useNativeControls
      displayLoadingIndicator
      videoStyle={styles.video}
      posterStyle={styles.video}
      style={[styles.video, { backgroundColor: colors.background }]}
    />
  );
};
