export enum Routes {
  about = "About",
  activeContests = "Active contests",
  askForAQuote = "Ask for a quote",
  cartOrders = "Cart",
  changePassword = "Change password",
  collabSuccess = "Collab success",
  contest = "Contest",
  contestEntries = "Contest entries",
  contests = "Contests",
  createCatalogProduct = "Create catalog product",
  createContest = "Create contest",
  createProductUpdate = "Create product update",
  createPromo = "Create promo",
  deleteAccount = "Delete my account",
  editProductUpdateField = "Edit product update field",
  editProfile = "Edit profile",
  editProfileField = "Edit profile field",
  feed = "Feed",
  feedback = "Feedback",
  godMode = "God mode",
  handleAuthAction = "Handle auth action",
  home = "Home",
  image = "Image",
  likes = "Likes",
  linkInstagramAccount = "Link your Instagram account",
  managePaymentMethods = "Manage payment methods",
  managePromos = "Manage promos",
  manageShippingAddress = "Shipping address",
  notFound = "not-found",
  notificationSettings = "Notification settings",
  notifications = "Notifications",
  notificationsPermission = "Notifications permission",
  onboardingStack = "Onboarding Stack",
  preorder = "Reserve this concept",
  preorderStack = "Preorder",
  product = "Product",
  productFromFeed = "Feed - Product",
  productUpdate = "Update",
  productUpdateStack = "Product update",
  productUpdates = "Updates",
  profile = "Profile",
  referAFriend = "Refer a friend",
  root = "root",
  search = "Search",
  searchResults = "Search results",
  searchStack = "SearchStack",
  seeAllCollabs = "See all collabs",
  seeAllVideos = "See all videos",
  settings = "Settings",
  startCampaign = "Start campaign",
  startCampaignFlow = "Start campaign flow",
  studio = "Studio",
  studioMiniCreateCollab = "Studio - Create concept",
  studioMiniEditField = "Studio - Edit Field",
  studioMiniExploreDetail = "Studio - Explore image detail",
  studioMiniGeneration = "Studio - Images generation",
  studioMiniSaved = "Studio - Saved images",
  studioMiniSavedDetails = "Studio - Saved image detail",
  studioMiniSelectImageSource = "Studio - Select image source",
  superplans = "Superplans",
  //TODO: remove all occurence of superseller route
  superseller = "Superseller",
  updateCollabStage = "Update stage",
  userInfo = "User info",
  video = "Video",
  webview = "Web view",
}

export enum Tabs {
  cartOrders = "cartOrdersTab",
  feed = "feedTab",
  home = "homeTab",
  profile = "profileTab",
  search = "searchTab",
  studio = "studioTab",
}
