import { StyleSheet } from "react-native";

const imageHeight = 168;

export const styles = StyleSheet.create({
  footerContainer: {
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 8,
    rowGap: 16,
  },
  image: {
    height: imageHeight,
    width: "100%",
  },
  imageContainer: {
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },
  linearGradient: {
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  startCampaignButton: {
    width: "100%",
  },
  stepContentContainer: {
    marginTop: -24,
    paddingBottom: 8,
    paddingHorizontal: 20,
    rowGap: 24,
    top: imageHeight,
  },
});
