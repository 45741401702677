import React, { FC } from "react";

import { Play, X } from "phosphor-react-native";
import { GestureResponderEvent, View } from "react-native";
import { FAB, useTheme } from "react-native-paper";

import { imageRatio } from "@app/common/constants/image.const";
import { lightTheme } from "@app/common/style/theme";
import { Image } from "@app/components/common/image/image.component";

import { imageWidth } from "../images-picker.style";

import { styles } from "./image-picker-item.style";

interface Props {
  imageUrl: string;
  type: "image" | "video";
  onRemove: (imageUrl: string) => void;
  aspectRatio?: number;
}

export const ImagePickerItem: FC<Props> = ({ imageUrl, onRemove, aspectRatio = imageRatio, type }) => {
  const { colors, roundness } = useTheme();

  const handleOnRemove = (e: GestureResponderEvent): void => {
    e.preventDefault();
    onRemove(imageUrl);
  };

  return (
    <>
      <Image source={imageUrl} width={imageWidth} style={{ aspectRatio, borderRadius: roundness }} />
      {type === "video" && (
        <View style={[{ backgroundColor: lightTheme.colors.action.active, borderTopLeftRadius: roundness }, styles.videoIndicator]}>
          <Play color={colors.common.white} size={24} weight="fill" />
        </View>
      )}
      <FAB onPress={handleOnRemove} icon={X} style={[{ backgroundColor: colors.onPrimary }, styles.deleteButton]} size="small" mode="elevated" />
    </>
  );
};
