/* eslint-disable max-lines */
import { gql } from "@apollo/client";

export const adFields = gql`
  fragment AdFields on Ad {
    adId
    createdAt
    icon
    link {
      ... on ExternalLink {
        externalLink
      }
      ... on InternalLink {
        internalLinkPath
      }
    }
    linkText
    text
    title
    updatedAt
  }
`;

export const featureAnnouncementFields = gql`
  fragment FeatureAnnouncementFields on FeatureAnnouncement {
    createdAt
    featureAnnouncementId
    icon
    link {
      ... on ExternalLink {
        externalLink
      }
      ... on InternalLink {
        internalLinkPath
      }
    }
    linkText
    text
    title
    updatedAt
  }
`;

export const coreStudioImageFields = gql`
  fragment CoreStudioImageFields on StudioImage {
    imageId
    imageUrl
    isExploreImage
    isNsfw
    prompt
    source
    status
  }
`;

export const coreStudioAnimationFields = gql`
  fragment CoreStudioAnimationFields on StudioAnimation {
    cloudflareId
    createdAt
    studioAnimationId
    studioAnimationStatus
    thumbnailUrl
    userId
    videoManifestUrl
    videoStatus: status
  }
`;

export const behindTheScenesVideoFields = gql`
  fragment BehindTheScenesVideoFields on BehindTheScenesVideo {
    behindTheScenesVideoId
    cloudflareId
    status
    thumbnailUrl
    videoManifestUrl
  }
`;

export const pushNotificationSettingsFields = gql`
  fragment PushNotificationSettingsFields on PushNotificationsUserSettings {
    accountUpdates
    collabProgress
    commentsAndReplies
    contestUpdates
    likes
    privateMessages
    productUpdates
    promotions
  }
`;

export const coreCollabImageFields = gql`
  fragment CoreCollabImageFields on CollabImage {
    collabImageId
    imageUrl
    source
  }
`;

export const profileVideoFields = gql`
  fragment ProfileVideoFields on ProfileVideo {
    cloudflareId
    profileVideoId
    status
    thumbnailUrl
    videoManifestUrl
  }
`;

export const coreProfileFields = gql`
  fragment CoreProfileFields on Profile {
    badge
    bio
    createdAt
    creatorStatus
    displayName
    externalLink
    imageUrl
    instagramUsername
    occupation
    pronouns
    shareId
    superseller
    tags
    twitterLink
    userId
    username
    video {
      ...ProfileVideoFields
    }
  }
  ${profileVideoFields}
`;

export const preorderFields = gql`
  fragment PreorderFields on Preorder {
    orderId
    paymentMethodId
    quantity
    supercoinAmount
    variantId
  }
`;

export const paymentMethodFields = gql`
  fragment PaymentMethodFields on CardPaymentMethod {
    brand
    expirationMonth
    expirationYear
    expiredOrExpiringSoon
    last4Digits
    paymentMethodId
  }
`;

export const coreCategoryFields = gql`
  fragment CoreCategoryFields on Category {
    categoryId
    imageUrl
    name
  }
`;

export const coreCategoryFieldsWithCount = gql`
  fragment CoreCategoryFieldsWithCount on CategoryWithCount {
    catalogProductsCount
    categoryId
    imageUrl
    name
  }
`;

export const contestFields = gql`
  fragment ContestFields on Contest {
    category {
      ...CoreCategoryFields
    }
    contestId
    description
    endOfVoteDate
    goLiveDate
    handle
    imageUrl
    isHidden
    name
    numberOfSubmissions
    passwordRequired
    rewardType
    rewardValue
    status
  }
  ${coreCategoryFields}
`;

export const contestFieldsWithoutCategory = gql`
  fragment ContestFieldsWithoutCategory on Contest {
    contestId
    description
    endOfVoteDate
    goLiveDate
    handle
    imageUrl
    isHidden
    name
    numberOfSubmissions
    passwordRequired
    rewardType
    rewardValue
    status
  }
`;

export const progressFields = gql`
  fragment ProgressFields on Progress {
    canCreateCollabUpdate
    canRequestQuote
    canStartCampaign
    canUpdateCollab
    inDevelopment
    reviewEndAt
    stage
    waitingForQuote
  }
`;

export const collabUpdateFields = gql`
  fragment CollabUpdateFields on CollabUpdate {
    collabId
    collabUpdateId
    content
    createdAt
    formattedLikeCount @client
    formattedNumberOfComments @client
    formattedShareCount @client
    images {
      collabUpdateImageId
      imageUrl
    }
    likeCount
    liked
    numberOfComments
    shareCount
    title
    user {
      badge
      creatorStatus
      displayName
      imageUrl
      userId
      username
    }
  }
`;

export const collabCampaignFields = gql`
  fragment CollabCampaignFields on CollabCampaign {
    orderCount
    orderCountNeeded
    percentage
    status
  }
`;

export const campaignVideoFields = gql`
  fragment CampaignVideoFields on CampaignVideo {
    campaignVideoId
    cloudflareId
    createdAt
    status
    thumbnailUrl
    videoManifestUrl
  }
`;

export const coreCollabFields = gql`
  fragment CoreCollabFields on Collab {
    availablePurchaseMethod
    campaign {
      ...CollabCampaignFields
    }
    category {
      ...CoreCategoryFields
    }
    collabId
    compareAtPrice
    createdAt
    creator {
      badge
      bio
      creatorStatus
      displayName
      imageUrl
      occupation
      pronouns
      superseller
      tags
      userId
      username
    }
    description
    formattedCompareAtPrice @client
    formattedNumberOfComments @client
    formattedNumberOfLikes @client
    formattedNumberOfShares @client
    formattedPrice @client
    handle
    images {
      ...CoreCollabImageFields
    }
    name
    numberOfComments
    numberOfLikes
    numberOfShares
    price
    progress {
      ...ProgressFields
    }
  }
  ${collabCampaignFields}
  ${progressFields}
  ${coreCollabImageFields}
  ${coreCategoryFields}
`;

export const collabFields = gql`
  fragment CollabFields on Collab {
    catalogProductId
    contest {
      ...ContestFields
    }
    ...CoreCollabFields
    inventoryQuantity
    liked
    sizeGuideUrl
  }
  ${coreCollabFields}
  ${contestFields}
`;

export const collabWithMediaFields = gql`
  fragment CollabWithMediaFields on CollabWithMedia {
    availablePurchaseMethod
    campaign {
      ...CollabCampaignFields
    }
    catalogProductId
    category {
      ...CoreCategoryFields
    }
    collabId
    compareAtPrice
    contest {
      ...ContestFields
    }
    createdAt
    creator {
      badge
      bio
      creatorStatus
      displayName
      imageUrl
      occupation
      pronouns
      superseller
      tags
      userId
      username
    }
    description
    formattedCompareAtPrice @client
    formattedNumberOfComments @client
    formattedNumberOfLikes @client
    formattedNumberOfShares @client
    formattedPrice @client
    handle
    media {
      ... on CampaignVideo {
        ...CampaignVideoFields
      }
      ... on CollabImage {
        ...CoreCollabImageFields
      }
      ... on StudioAnimation {
        ...CoreStudioAnimationFields
      }
    }
    name
    numberOfComments
    numberOfLikes
    numberOfShares
    price
    progress {
      ...ProgressFields
    }
    sizeGuideUrl
    thumbnailImage {
      ...CoreCollabImageFields
    }
  }
  ${collabCampaignFields}
  ${contestFields}
  ${coreStudioAnimationFields}
  ${progressFields}
  ${campaignVideoFields}
  ${coreCollabImageFields}
  ${coreCategoryFields}
`;

export const productVariantFields = gql`
  fragment ProductVariantFields on ProductVariant {
    color
    compareAtPrice
    formattedCompareAtPrice @client
    formattedPrice @client
    inventoryQuantity
    material
    price
    size
    style
    variantId
  }
`;

export const collabFacetsFields = gql`
  fragment CollabFacetsFields on CollabFacets {
    categories {
      category {
        ...CoreCategoryFields
      }
      resultCount
    }
    creators {
      creator {
        badge
        creatorStatus
        displayName
        imageUrl
        occupation
        userId
        username
      }
      resultCount
    }
    stages {
      resultCount
      stage
    }
  }
  ${coreCategoryFields}
`;

export const commentFields = gql`
  fragment CommentFields on Comment {
    commentId
    content
    createdAt
    formattedLikeCount @client
    likeCount
    liked
    user {
      badge
      creatorStatus
      displayName
      imageUrl
      userId
      username
    }
  }
`;

export const likeFields = gql`
  fragment LikeFields on Like {
    collabId
    numberOfLikes
    userId
  }
`;

export const userFacetsFields = gql`
  fragment UserFacetsFields on UserFacets {
    isCreator {
      isCreator
      resultCount
    }
  }
`;

export const userStateFields = gql`
  fragment UserStateFields on UserState {
    frontendState
    userId
  }
`;

export const cartItemFields = gql`
  fragment CartItemFields on CartItem {
    cartItemId
    collab {
      ...CollabFields
    }
    quantity
    variant {
      ...ProductVariantFields
    }
  }
  ${collabFields}
  ${productVariantFields}
`;

export const coreCartItemFields = gql`
  fragment CoreCartItemFields on CartItem {
    cartItemId
    collab {
      collabId
    }
    quantity
    variant {
      ...ProductVariantFields
    }
  }
  ${productVariantFields}
`;

export const cartFields = gql`
  fragment CartFields on Cart {
    anonymousCartId
    items {
      ...CartItemFields
    }
    userId
  }
  ${cartItemFields}
`;

export const catalogProductFields = gql`
  fragment CatalogProductFields on CatalogProduct {
    catalogProductId
    createdAt
    imageUrl
    name
    updatedAt
  }
`;

export const orderFields = gql`
  fragment OrderFields on Order {
    formattedTotalPrice @client
    isPreorder
    lineItems {
      collab {
        ...CollabFields
      }
      formattedPrice @client
      price
      quantity
      variant {
        ...ProductVariantFields
      }
    }
    orderId
    orderPageUrl
    paymentFailedAt
    paymentInError
    paymentWillBeRetriedAt
    salesTaxPrice
    shippingPrice
    status
    subtotalPrice
    supercoinAmount
    totalPrice
  }
  ${collabFields}
  ${productVariantFields}
`;

export const standardPromoFieldsWithoutCollab = gql`
  fragment StandardPromoWithoutCollabFields on StandardPromo {
    createdAt
    ctaLabel
    ctaUrl
    imageUrl
    promoId
    promoType
    standardSubtitle: subtitle
    standardText: text
    standardTitle: title
    updatedAt
  }
`;

export const genericPromoFields = gql`
  fragment GenericPromoFields on GenericPromo {
    createdAt
    ctaLabel
    ctaUrl
    endAt
    genericSubtitle: subtitle
    genericText: text
    genericTitle: title
    imageUrl
    promoId
    promoType
    startAt
    updatedAt
  }
`;

export const standardPromoFields = gql`
  fragment StandardPromoFields on StandardPromo {
    collab {
      ...CoreCollabFields
    }
    contest {
      ...ContestFields
    }
    ...StandardPromoWithoutCollabFields
  }
  ${contestFields}
  ${coreCollabFields}
  ${standardPromoFieldsWithoutCollab}
`;

export const standardPromoInFeedFields = gql`
  fragment StandardPromoInFeedFields on StandardPromo {
    collab {
      ...CollabFields
    }
    contest {
      ...ContestFields
    }
    ...StandardPromoWithoutCollabFields
  }
  ${contestFields}
  ${collabFields}
  ${standardPromoFieldsWithoutCollab}
`;

export const shippingAddressFields = gql`
  fragment ShippingAddressFields on ShippingAddress {
    city
    country
    line1
    line2
    name
    postalCode
    state
  }
`;

export const milestoneProgressFields = gql`
  fragment MilestoneProgressFields on MilestoneProgress {
    achievementCount
    currentCount
    isHidden
    isRecurring
    milestoneObjectiveId
    milestoneType
    progression
    requiredCount
    reward
  }
`;

export const featureAccessFields = gql`
  fragment FeatureAccessFields on FeatureAccess {
    allowed
    requiredRole
  }
`;
