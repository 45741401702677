import React, { FC } from "react";

import { ResizeMode } from "expo-av";
import { Image } from "expo-image";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";

import { imageRatio } from "@app/common/constants/image.const";
import { StudioMedia } from "@app/common/graphql/generated/schema.graphql";
import { Skeleton } from "@app/components/common/skeleton/skeleton.component";
import { Text } from "@app/components/common/text/text.component";
import { Video } from "@app/components/common/video/video.component";
import { previewWidth, useImage } from "@app/hooks/utils/use-image.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { isStudioImage, isStudioVideo } from "@app/utils/studio-media.util";

import { styles } from "./studio-image-list-details.style";
import { StudioImageSideIconButtons } from "./studio-image-side-icon-buttons/studio-image-side-icon-buttons.component";

interface Props {
  media?: StudioMedia;
  context: "saved" | "explore";
}

export const StudioImageListDetails: FC<Props> = ({ media, context }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const { width: windowWidth } = useWindowDimensions();
  const { getResponsiveImageUrl, getPreviewImageUrl } = useImage({ width: windowWidth });
  const imageWidth = windowWidth;
  const imageHeight = imageWidth * imageRatio;

  const imageUrl = isStudioImage(media) ? media.imageUrl : media?.thumbnailUrl;
  const previewSource = { uri: getPreviewImageUrl(imageUrl), width: previewWidth, height: previewWidth };
  const isVideo = isStudioVideo(media) && media.videoManifestUrl;

  return (
    <View style={styles.root}>
      <View>
        {!isVideo && (
          <Image
            source={previewSource}
            placeholder={previewSource}
            placeholderContentFit="cover"
            responsivePolicy="initial"
            cachePolicy="memory-disk"
            contentFit="cover"
            style={{ height: imageHeight, backgroundColor: colors.skeleton }}
          />
        )}
        {isStudioImage(media) ? (
          <Image
            source={{ uri: getResponsiveImageUrl(imageUrl), width: imageWidth, height: imageHeight }}
            placeholder={previewSource}
            placeholderContentFit="cover"
            cachePolicy="memory-disk"
            contentFit="contain"
            transition={200}
            style={[{ height: imageHeight, width: imageWidth, backgroundColor: colors.skeleton }, styles.image]}
          />
        ) : isVideo && media.videoManifestUrl ? (
          <Video
            cloudflareId={media.cloudflareId}
            source={{ uri: media.videoManifestUrl }}
            posterSource={{ uri: media.thumbnailUrl }}
            displayLoadingIndicator
            shouldPlay
            isLooping
            resizeMode={ResizeMode.CONTAIN}
            height={imageHeight}
            width={imageWidth}
            style={{ backgroundColor: colors.skeleton }}
          />
        ) : (
          <ActivityIndicator size="large" style={styles.loading} />
        )}
        {media && <StudioImageSideIconButtons media={media} context={context} />}
      </View>

      {isStudioImage(media) && (
        <View style={styles.textContainer}>
          <Text variant="h7">{t("studioMini.saved.prompt")}</Text>
          <Skeleton width="100%">
            {media?.prompt ? (
              <Text variant="body2" color="tertiary">
                {media?.prompt}
              </Text>
            ) : null}
          </Skeleton>
        </View>
      )}
    </View>
  );
};
