import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  loadingContainer: {
    alignItems: "center",
    borderWidth: 1,
    justifyContent: "center",
  },
  video: {
    aspectRatio: 1,
    width: "100%",
  },
});
