import { StudioAnimation } from "../graphql/generated/schema.graphql";

export interface ImageToBeUsed {
  imageId: string;
  imageUrl: string;
  imageName?: string | null;
  index?: number;
  imageSource?: "upload" | "saved";
}

export interface VideoToBeUsed extends Omit<StudioAnimation, "studioAnimationStatus" | "videoStatus" | "userId"> {
  videoName?: string | null;
  index?: number;
  videoSource?: "upload" | "saved";
}

export type MediaToBeUsed = ImageToBeUsed | VideoToBeUsed;

export function isImageToBeUsed(media?: MediaToBeUsed): media is ImageToBeUsed {
  return !!media && "imageId" in media;
}

export function isVideoToBeUsed(media: MediaToBeUsed): media is VideoToBeUsed {
  return "studioAnimationId" in media;
}

export function getMediaToBeUsedId(media: MediaToBeUsed): string {
  if (isImageToBeUsed(media)) return media.imageId;
  return media.studioAnimationId;
}

export function getMediaToBeUsedImageUrl(media: MediaToBeUsed): string | undefined {
  if (isImageToBeUsed(media)) return media.imageUrl;
  return media.thumbnailUrl;
}
