import React, { FC, useState } from "react";

import { RocketLaunch } from "phosphor-react-native";
import { useTranslation } from "react-i18next";

import FinancialQuote from "@app/assets/icons/financial-quote.svg";
import { CollabSuccessType } from "@app/common/enums/collab-success-type.enum";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { FeatureFlag } from "@app/context/launch-darkly/feature-flag.enum";
import { useLaunchDarklyContext } from "@app/context/launch-darkly/launch-darkly.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useStartCampaign } from "@app/hooks/api/products/use-start-campaign.hook";
import { StartCampaignProps } from "@app/hooks/utils/use-start-campaign-button.hook";

import { StartCampaignConfirmationDialog } from "../start-campaign-confirmation-dialog/start-campaign-confirmation-dialog.component";

interface Props extends StartCampaignProps, Omit<ButtonProps, "children"> {
  collabId: string;
}

export const StartCampaignButton: FC<Props> = ({ collabId, progress, ...buttonProps }) => {
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();
  const navigation = useNavigation();
  const { isFeatureEnabled } = useLaunchDarklyContext();

  const { startCampaign, loading: loadingStartCampaign } = useStartCampaign();

  const fullStartCampaignFlowEnabled = isFeatureEnabled(FeatureFlag.fullStartCampaignFlow);
  const loading = loadingStartCampaign;

  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);

  const handlePress = (): void => {
    if (fullStartCampaignFlowEnabled) {
      void startCampaign({
        variables: { collabId },
        onCompleted: () => navigation.navigate(Routes.collabSuccess, { collabId, type: CollabSuccessType.campaignStarted }),
        onError: err => showErrorSnackbar({ error: err }),
      });
    } else {
      setConfirmationDialogVisible(true);
    }
  };

  const handleDismiss = (): void => {
    setConfirmationDialogVisible(false);
  };

  return (
    <>
      <Button
        {...buttonProps}
        loading={loading}
        icon={iconProps => (progress.canRequestQuote ? <FinancialQuote {...iconProps} /> : <RocketLaunch {...iconProps} weight="fill" />)}
        onPress={handlePress}>
        {t("startCampaign.label")}
      </Button>

      <StartCampaignConfirmationDialog collabId={collabId} visible={confirmationDialogVisible} onDismiss={handleDismiss} />
    </>
  );
};
