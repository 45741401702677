export enum TrackEvent {
  actionFromInAppBrowserScreen = "Action from in-app browser screen",
  actionFromOnboardingStep = "Action from onboarding step",
  adInteraction = "Ad interaction",
  dismissAd = "Dismiss ad",
  dismissFeatureAnnouncement = "Dismiss feature announcement",
  featureAnnouncementInteraction = "Feature announcement interaction",
  inAppNotifInteraction = "In-app notif interaction",
  infoClickOnFeed = "Click on info on feed",
  initialPageLoad = "Initial page load in web",
  openAppFromWeb = "Open app from web",
  pushNotifInteraction = "Push notification interaction",
  pushNotifPermissionStatusChanged = "Push notification permission status changed",
  seeAllOnHomeScreen = "See all on Home screen",
  supercreatorSubscribe = "Tap subscribe to supercreator",
  supersellerSubscribe = "Tap subscribe to superseller",
  viewContests = "View contests",
  viewInAppBrowserScreen = "View in-app browser screen",
  viewSuperplanScreen = "View superplan screen",
}

export enum OnboardingStep {
  linkInstagramAccount = "linkInstagramAccount",
  notificationsPermission = "notificationsPermission",
  userInfo = "userInfo",
}

export enum ActionFromOnboardingStep {
  next = "next",
  skip = "skip",
}

export enum ActionFromInAppBrowserScreen {
  continueOnBrowser = "continue on browser",
  openApp = "open App",
}

export enum ViewContestsOrigin {
  search = "search",
  studio = "studio",
}

export enum ViewSuperplanScreenOrigin {
  browseCampaigns = "browse campaigns",
  featureAnnouncement = "featureAnnouncement",
  ongoingCampaigns = "ongoingCampaigns",
  profileMoreMenu = "profile more menu",
  startCampaignDialog = "start campaign dialog",
  studioTab = "studioTab",
  studioTokensBottomSheet = "studioTokensBottomSheet",
}
