import React, { FC, ReactNode } from "react";

import { Plus } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View, TouchableOpacity, StyleProp, ViewStyle } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";
import Animated from "react-native-reanimated";

import { getMediaToBeUsedImageUrl, MediaToBeUsed } from "@app/common/types/image.type";
import { Text } from "@app/components/common/text/text.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useUploadNewImage } from "@app/hooks/utils/use-upload-new-image.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { pickImages } from "@app/utils/image-picker.util";
import { reportError } from "@app/utils/logger/logger.util";

import { DraggableImages } from "./draggable-images/draggable-images.component";
import { ImagePickerItem } from "./image-picker-item/image-picker-item.component";
import { imageHeight, itemSize, styles, verticalGap } from "./images-picker.style";

interface Props {
  media: MediaToBeUsed[];
  legend?: ReactNode;
  allowMultipleSelection?: boolean;
  required?: boolean;
  style?: StyleProp<ViewStyle>;
  setMedia: (callback: (prevImages: MediaToBeUsed[]) => MediaToBeUsed[]) => void;
  onPressAddImage?: () => void;
  onImageDeleted?: (imageUrl: string) => void;
}

export const ImagesPicker: FC<Props> = ({
  style,
  media,
  setMedia,
  legend,
  allowMultipleSelection = true,
  required = true,
  onPressAddImage,
  onImageDeleted,
}) => {
  const { colors, roundness } = useTheme();
  const { t } = useTranslation();
  const { profile } = useProfileContext();

  const { uploadNewImage, loading: loadingNewImage } = useUploadNewImage();

  const userId = profile?.userId;

  const handleAddImage = async (): Promise<void> => {
    if (onPressAddImage) {
      onPressAddImage();
    } else {
      try {
        const imagesResult = await pickImages({ selectionLimit: !allowMultipleSelection ? 1 : 0 });
        if (imagesResult.error || !imagesResult.assets?.length) return;

        const imagesAsInputs = imagesResult.assets.map(f => ({ media: { imageUrl: f.uri, imageName: f.fileName, imageId: "" }, userId, setMedia }));

        await Promise.all(imagesAsInputs.map(image => uploadNewImage(image)));
      } catch (e) {
        reportError(e as Error);
      }
    }
  };

  const handleRemoveImage = (imageUrl: string): void => {
    setMedia?.(imgs => imgs.filter(i => getMediaToBeUsedImageUrl(i) !== imageUrl));
    onImageDeleted?.(imageUrl);
  };

  const handleOrderChange = (newMedia: MediaToBeUsed[]): void => {
    setMedia?.(() => newMedia);
  };

  return (
    <View
      style={[
        style,
        styles.root,
        ...conditionalItem<ViewStyle>(!allowMultipleSelection || media.length <= 1 || !media.length, {
          ...styles.center,
          height: imageHeight + 32,
        }),
      ]}>
      {legend}
      <Animated.ScrollView
        horizontal
        scrollEnabled={allowMultipleSelection && media.length >= 1}
        scrollEventThrottle={16}
        contentContainerStyle={[
          styles.scrollViewContainer,
          ...conditionalItem<ViewStyle>(!media.length || !allowMultipleSelection, { justifyContent: "center", paddingHorizontal: verticalGap }),
          ...conditionalItem<ViewStyle>(media.length < 1 || !allowMultipleSelection, styles.fullWidth, [{ width: (media.length + 1) * itemSize }]),
        ]}>
        <View style={[styles.rowContainer, { height: imageHeight + 32 }]}>
          {!allowMultipleSelection && media.length ? null : (
            <TouchableOpacity onPress={() => void handleAddImage()} disabled={loadingNewImage}>
              <View
                style={[
                  styles.rowItem,
                  styles.addContainer,
                  { borderColor: colors.outline, backgroundColor: colors.surfaceVariant, borderRadius: roundness },
                ]}>
                {loadingNewImage ? <ActivityIndicator color={colors.outline} /> : <Plus color={colors.outline} />}
                <Text variant="labelSmall" textAlign="center" style={styles.addImageText}>
                  {t("studioMini.addImage", { context: required === false ? "optional" : undefined })}
                </Text>
              </View>
            </TouchableOpacity>
          )}
          {allowMultipleSelection && media.length ? (
            <DraggableImages media={media} onImageOrderChange={handleOrderChange} onRemove={handleRemoveImage} />
          ) : null}
          {!allowMultipleSelection && media.length ? (
            <View key={getMediaToBeUsedImageUrl(media[0])}>
              <ImagePickerItem
                imageUrl={getMediaToBeUsedImageUrl(media[0]) ?? ""}
                type={"imageUrl" in media ? "image" : "video"}
                onRemove={handleRemoveImage}
              />
            </View>
          ) : null}
        </View>
      </Animated.ScrollView>
    </View>
  );
};
