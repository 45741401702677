import React, { FC, JSX, useEffect, useState, useCallback, useRef, ReactNode, useMemo } from "react";

import { LinearGradient } from "expo-linear-gradient";
import { ArrowLeft } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { FlatList, ListRenderItem, View } from "react-native";
import { IconButton, useTheme } from "react-native-paper";

import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { PitchVideoPreview } from "@app/components/campaign/pitch-video-preview/pitch-video-preview.component";
import { StartCampaignButton } from "@app/components/campaign/start-campaign-button/start-campaign-button.component";
import { StartCampaignFacts } from "@app/components/campaign/start-campaign-facts/start-campaign-facts.component";
import { TipsPitchVideo } from "@app/components/campaign/tips-pitch-video/tips-pitch-video.component";
import { UploadPitchVideo } from "@app/components/campaign/upload-pitch-video/upload-pitch-video.component";
import { UploadPitchVideoButton } from "@app/components/campaign/upload-pitch-video-button/upload-pitch-video-button.component";
import { Button } from "@app/components/common/button/button.component";
import { Image } from "@app/components/common/image/image.component";
import { PageControl } from "@app/components/common/page-control/page-control.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { useCollabWithMedia } from "@app/hooks/api/products/use-collab-with-media.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { isWeb } from "@app/utils/device.util";
import { isObjectWithProperties } from "@app/utils/object.util";

import { styles } from "./start-campaign-flow.style";

enum Step {
  facts = 0,
  launch = 3,
  uploadVideo = 1,
  videoUploaded = 2,
}

interface StepInterface {
  title: string;
  content: ReactNode;
  id: Step;
  button:
    | {
        label: string;
        onPress: () => void;
        disabled?: boolean;
      }
    | JSX.Element;
}

export const StartCampaignFlowScreen: FC<NavigationProps<Routes.startCampaignFlow>> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const { dark, colors } = useTheme();
  const { width } = useWindowDimensions();
  const listRef = useRef<FlatList<StepInterface>>(null);

  const collabHandle = route.params?.collabHandle;
  const { data: collab, loading: loadingCollab } = useCollabWithMedia({ variables: { handle: collabHandle ?? "" } });

  const [factsRead, setFactsRead] = useState(false);
  const [currentStep, setCurrentStep] = useState(Step.facts);
  const [pitchVideo, setPitchVideo] = useState<string | undefined>(undefined);

  const backgroundColorIconButton = "rgba(0,0,0,0.25)";
  const gradients = dark
    ? ["rgba(0,0,0,0.0)", "rgba(0,0,0,0.0)", "rgba(0,0,0,1)"]
    : ["rgba(255,255,255,0.0)", "rgba(255,255,255,0.0)", "rgba(255,255,255,1)"];

  useEffect(() => {
    const cannotStartCampaign = collab && !collab.progress.canStartCampaign;
    const collabNotFound = !collab && !loadingCollab;
    if (cannotStartCampaign || collabNotFound) {
      navigation.goBack();
    }
  }, [collab, loadingCollab, navigation]);

  const handleNext = useCallback(() => setCurrentStep(prev => prev + 1), []);

  const steps = useMemo<StepInterface[]>(
    () => [
      {
        id: Step.facts,
        title: t("startCampaign.facts.title"),
        content: <StartCampaignFacts factsRead={factsRead} setFactsRead={setFactsRead} />,
        button: { label: t("cta.next"), disabled: !factsRead, onPress: handleNext },
      },
      {
        id: Step.uploadVideo,
        title: t("startCampaign.uploadVideo.title"),
        content: <UploadPitchVideo />,
        button: <UploadPitchVideoButton pitchVideo={pitchVideo} setPitchVideo={setPitchVideo} onCompleted={handleNext} />,
      },
      {
        id: Step.videoUploaded,
        title: t("startCampaign.videoUploaded.title"),
        content: <PitchVideoPreview pitchVideo={pitchVideo} />,
        button: { label: t("cta.next"), onPress: handleNext },
      },
      {
        id: Step.launch,
        title: t("startCampaign.readyToStart.title"),
        content: <TipsPitchVideo />,
        button: collab ? (
          <StartCampaignButton
            mode="contained"
            size="large"
            collabId={collab.collabId}
            creatorId={collab.creator.userId}
            progress={collab.progress}
            containerStyle={styles.startCampaignButton}
          />
        ) : (
          <Button mode="contained" size="large" loading disabled>
            {t("startCampaign.label")}
          </Button>
        ),
      },
    ],
    [collab, factsRead, handleNext, pitchVideo, t],
  );

  const currentButton = steps[currentStep].button;

  useEffect(() => {
    isWeb
      ? listRef.current?.scrollToOffset({ offset: currentStep * width, animated: false })
      : listRef.current?.scrollToIndex({ index: currentStep });
  }, [currentStep, width]);

  const renderStep = useCallback<ListRenderItem<StepInterface>>(
    ({ item }) => (
      <View style={[styles.stepContentContainer, { width }]}>
        <Text variant="title" textAlign="center">
          {item.title}
        </Text>

        {item.content}
      </View>
    ),
    [width],
  );

  const handleBack = useCallback(() => {
    if (currentStep === 0) {
      navigation.goBack();
      return;
    }

    setCurrentStep(prevStep => {
      let newStep = prevStep - 1;
      if (newStep === Step.videoUploaded && !pitchVideo) newStep--;
      return newStep;
    });
  }, [currentStep, navigation, pitchVideo]);

  const handleSkipUploadVideo = useCallback(() => {
    setCurrentStep(Step.launch);
    setPitchVideo(undefined);
  }, []);

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <IconButton icon={ArrowLeft} iconColor={colors.common.white} onPress={handleBack} style={{ backgroundColor: backgroundColorIconButton }} />
      ),
      headerRight:
        currentStep === Step.uploadVideo
          ? () => (
              <IconButton
                icon={() => (
                  <Text variant="body1" color="common.white">
                    {t("cta.skip")}
                  </Text>
                )}
                style={{ backgroundColor: backgroundColorIconButton }}
                onPress={handleSkipUploadVideo}
              />
            )
          : undefined,
    });
  }, [colors.common.white, currentStep, handleBack, handleNext, handleSkipUploadVideo, navigation, t]);

  return (
    <ScreenWrapper
      safeAreaEdges={["right", "left", "bottom"]}
      withScrollView
      staticContent={
        <View style={styles.footerContainer}>
          <PageControl currentPage={currentStep} numberOfPages={steps.length} />

          {typeof currentButton === "object" && isObjectWithProperties(currentButton, "label") ? (
            <Button fullWidth mode="contained" size="large" disabled={currentButton.disabled} onPress={currentButton.onPress}>
              {currentButton.label}
            </Button>
          ) : (
            <>{steps[currentStep].button}</>
          )}
        </View>
      }>
      <View style={styles.imageContainer}>
        <Image source={collab?.thumbnailImage?.imageUrl} style={styles.image} />
        <LinearGradient locations={[0, 0.67, 1]} colors={gradients} style={styles.linearGradient} />
      </View>

      <FlatList
        ref={listRef}
        initialNumToRender={steps.length}
        data={steps}
        contentContainerStyle={isWeb ? { width } : undefined}
        keyExtractor={item => item.title}
        horizontal
        scrollEnabled={false}
        pagingEnabled
        renderItem={renderStep}
        showsHorizontalScrollIndicator={false}
        getItemLayout={(_data, index) => ({ length: width, offset: index * width, index })}
      />
    </ScreenWrapper>
  );
};
