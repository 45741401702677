import { gql, LazyQueryExecFunction, useLazyQuery } from "@apollo/client";

import { UploadVideoInitialContainer } from "@app/common/graphql/generated/schema.graphql";
import { BaseQueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  uploadVideoInitialContainer: UploadVideoInitialContainer;
}

interface Variables {
  userId: string;
}

export interface UploadVideoInitialContainerResult extends BaseQueryResult<Response, "uploadVideoInitialContainer"> {
  getUploadVideoInitialContainer: LazyQueryExecFunction<Response, Variables>;
}

const uploadVideoInitialContainerQuery = gql`
  query UploadVideoInitialContainer($userId: UserId!) {
    uploadVideoInitialContainer(userId: $userId) {
      uploadVideoId
      url
    }
  }
`;

export function useUploadVideoInitialContainerQuery(): UploadVideoInitialContainerResult {
  const [getUploadVideoInitialContainer, { loading, error, data }] = useLazyQuery<Response, Variables>(uploadVideoInitialContainerQuery);

  return { getUploadVideoInitialContainer, loading, error, data: data?.uploadVideoInitialContainer };
}
